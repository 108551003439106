import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Circle, Line, Image as KonvaImage } from 'react-konva';
import { useSpring, animated } from 'react-spring';
import useImage from 'use-image';
import { useMediaQuery } from '@mui/material';

const AnimatedInfoBox = ({ face, isVisible, lineEndPoint }) => {
    const boxAnimation = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
        config: { tension: 200, friction: 20 },
        delay: 1000,
    });

    return (
        <animated.div
            style={{
                ...boxAnimation,
                position: 'absolute',
                top: lineEndPoint.y - 40,
                left: lineEndPoint.x - 100,
                width: '200px',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '10px',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
        >
            <h4>{face.name}</h4>
            <p>{face.p1}</p>
            <p>{face.p2}</p>
        </animated.div>
    );
};

const FaceDetectionAnimation = ({ imageSrc, faces = [] }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [lineVisible, setLineVisible] = useState(false);
    const [image] = useImage(imageSrc);
    const imageRef = useRef(null);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
                if (entry.isIntersecting) {
                    setTimeout(() => setLineVisible(true), 1000);
                } else {
                    setLineVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (imageRef.current) observer.observe(imageRef.current);

        return () => {
            if (imageRef.current) observer.unobserve(imageRef.current);
        };
    }, []);

    return (
        <div style={{ position: 'relative', width: '100%' }} ref={imageRef}>
            <Stage width={isSmallScreen ? 300 : 600} height={isSmallScreen ? 325 : 650}>
                <Layer>
                    {image && (
                        <KonvaImage
                            image={image}
                            width={isSmallScreen ? 300 : 600}
                            height={(image.height / image.width) * (isSmallScreen ? 300 : 600)}
                            offsetX={isSmallScreen ? 150 : 300}
                            offsetY={isSmallScreen ? 160 : 200}
                            x={isSmallScreen ? 150 : 300}
                            y={isSmallScreen ? 160 : 200}
                            draggable
                        />
                    )}
                    {faces.map((face, index) => (
                        <React.Fragment key={index}>
                            <Circle
                                x={(face.x * (isSmallScreen ? 0.5 : 1)) + face.width / 2}
                                y={(face.y * (isSmallScreen ? 0.5 : 1)) + face.height / 2}
                                radius={(face.width / 2) * (isSmallScreen ? 0.5 : 1)}
                                stroke="#f9f9f9"
                                opacity={isVisible ? 1 : 0.3}
                                strokeWidth={2}
                                dash={[10, 5]}
                            />
                            {isVisible && lineVisible && (
                                <Line
                                    points={[
                                        (face.x * (isSmallScreen ? 0.5 : 1)) + face.width / 2,
                                        (face.y * (isSmallScreen ? 0.5 : 1)) + face.height,
                                        (face.x * (isSmallScreen ? 0.5 : 1)) + face.width / 2,
                                        (face.y * (isSmallScreen ? 0.5 : 1)) + face.height + 100,
                                    ]}
                                    stroke="#f9f9f9"
                                    strokeWidth={2}
                                    dash={[10, 5]}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Layer>
            </Stage>
            {faces.map((face, index) => (
                <AnimatedInfoBox
                    key={index}
                    face={face}
                    isVisible={isVisible}
                    lineEndPoint={{
                        x: (face.x * (isSmallScreen ? 0.5 : 1)) + face.width / 2,
                        y: (face.y * (isSmallScreen ? 0.5 : 1)) + face.height + 100,
                    }}
                />
            ))}
        </div>
    );
};

export default FaceDetectionAnimation;
