import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import CustomSideBar from './Sidebar';
import { styled } from '@mui/system';
import Header from 'components/global/Header';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';

// Layout wrapper to ensure proper positioning
const LayoutWrapper = styled('div')({
  display: 'flex',
  height: '100vh',
  overflow: 'hidden', // Prevent body scroll
});

// Style for the main content area
const MainContent = styled('div')(({ theme, isMobile, isCollapsed }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  marginLeft: isMobile ? 0 : 0,
  transition: 'margin-left 0.3s ease',
  overflow: 'hidden',
}));

// Scrollable content container
const ScrollableContent = styled('div')({
  flex: 1,
  overflow: 'auto',
});

// Fixed header container
const HeaderContainer = styled('div')({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 100,
  marginLeft: 'inherit', // Inherit margin from parent to align with sidebar
});

// Mobile menu button container
const MenuButtonContainer = styled('div')({
  position: 'fixed',
  top: '10px',
  left: '10px',
  zIndex: 1100,
});

const ClubLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/dashboard/project/')) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [location.pathname]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <LayoutWrapper>
      {isMobile && (
        <MenuButtonContainer>
          <IconButton 
            onClick={toggleSidebar}
            className="bg-white shadow-md"
          >
            <MenuIcon />
          </IconButton>
        </MenuButtonContainer>
      )}

      {!isMobile && (
        <CustomSideBar isCollapsed={isCollapsed} />
      )}

      <MainContent isMobile={isMobile} isCollapsed={isCollapsed}>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        
        <ScrollableContent>
          <Outlet />
        </ScrollableContent>
      </MainContent>

      {isMobile && (
        <Drawer
          anchor="left"
          open={sidebarOpen}
          onClose={toggleSidebar}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 250,
            },
          }}
        >
          <CustomSideBar isCollapsed={false} />
        </Drawer>
      )}
    </LayoutWrapper>
  );
};

export default ClubLayout;