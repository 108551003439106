import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  CircularProgress,
  Snackbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import ValidationInfoDialog from 'components/club/ValidationInfoDialog'; // Import the new component

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Validations = () => {
  const [validations, setValidations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [currentValidation, setCurrentValidation] = useState(null);
  const authToken = localStorage.getItem('authToken');

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const handleValidate = async () => {
    try {
      const response = await axios.post('https://api.datalgeria.com/club/validateMember', {
        id: currentValidation.id, // Pass the user_id from the selected validation
      }, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        setSnackbarMessage('Member validated successfully.');
        setSnackbarOpen(true);
        fetchValidations(page + 1, search); // Refresh validations after validation
      } else {
        setSnackbarMessage('Validation failed.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error validating member:', error);
      setSnackbarMessage('Error validating member.');
      setSnackbarOpen(true);
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const handleReject = async () => {
    try {
      const response = await axios.post('https://api.datalgeria.com/club/rejectValidation', {
        user_id: currentValidation.user_id,
        reason: rejectReason,
      }, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        setSnackbarMessage('Validation rejected successfully.');
        setSnackbarOpen(true);
        fetchValidations(page + 1, search); // Refresh validations after rejection
      } else {
        setSnackbarMessage('Rejection failed.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error rejecting validation:', error);
      setSnackbarMessage('Error rejecting validation.');
      setSnackbarOpen(true);
    } finally {
      setRejectDialogOpen(false);
      setRejectReason(''); // Reset reason input
    }
  };

  const fetchValidations = async (page = 1, search = '') => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.datalgeria.com/club/getValidations', {
        params: { page, search },
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        setValidations(response.data.data);
        setTotal(response.data.total);
      } else {
        setSnackbarMessage('Failed to fetch validations.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error fetching validations:', error);
      setSnackbarMessage('Error fetching validations.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchValidations(page + 1, search);
  }, [page, search]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleInfoClick = (validation) => {
    setCurrentValidation(validation);
    setInfoDialogOpen(true);
  };

  return (
    <Box sx={{ 
        
        padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Validations
      </Typography>

      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={search}
          onChange={handleSearchChange}
          size="small"
          sx={{ mr: 1, flex: 1 }}
        />
        <IconButton color="primary" onClick={() => fetchValidations(page + 1, search)}>
          <SearchIcon />
        </IconButton>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Wilaya</TableCell>
                <TableCell>Commune</TableCell>
                <TableCell>University</TableCell>
                <TableCell>Is Working</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {validations.map((validation) => (
                <TableRow key={validation.id}>
                  <TableCell>{validation.name}</TableCell>
                  <TableCell>{validation.fname}</TableCell>
                  <TableCell>{validation.email}</TableCell>
                  <TableCell>{validation.phone}</TableCell>
                  <TableCell>{validation.wilaya_id}</TableCell>
                  <TableCell>{validation.commune_id}</TableCell>
                  <TableCell>{validation.university}</TableCell>
                  <TableCell>{validation.is_working ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <IconButton color="info" onClick={() => handleInfoClick(validation)}>
                      <InfoIcon />
                    </IconButton>
                    <IconButton
                      color="success"
                      onClick={() => {
                        setCurrentValidation(validation);
                        setConfirmDialogOpen(true);
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setCurrentValidation(validation);
                        setRejectDialogOpen(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      {/* Confirm Validation Dialog */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirm Validation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to validate this entry?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleValidate} color="primary" autoFocus>
            Validate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reject Validation Dialog */}
      <Dialog open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)}>
        <DialogTitle>Reject Validation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a reason for rejecting this entry.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRejectDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReject} color="primary">
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Info Dialog */}
      <ValidationInfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        validation={currentValidation}
      />
    </Box>
  );
};

export default Validations;
