import axios from 'axios';

const API_BASE_URL = 'https://api.datalgeria.com/files';

export const getFilesAndFolders = async (projectId, folderId, page, password) => {
  const response = await axios.get(`${API_BASE_URL}/files-folders`, {
    params: { project_id: projectId, folder_id: folderId, page, password },
    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
  });
  return response.data;
};

export const createFolder = async (projectId, name, parentFolderId, password) => {
  return await axios.post(
    `${API_BASE_URL}/add-folder`,
    { project_id: projectId, name, parent_folder: parentFolderId, password },
    { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
  );
};

export const uploadFile = async (projectId, folderId, file, name, password) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('project_id', projectId);
  formData.append('folder_id', folderId);
  formData.append('name', name);
  formData.append('password', password);

  return await axios.post(`${API_BASE_URL}/upload-file`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const renameItem = async (id, name, type) => {
  return await axios.put(
    `${API_BASE_URL}/rename`,
    { id, name, type },
    { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
  );
};

export const deleteItem = async (id, type) => {
  return await axios.delete(`${API_BASE_URL}/delete`, {
    data: { id, type },
    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
  });
};
