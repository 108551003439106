import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography, Chip, CircularProgress, LinearProgress } from '@mui/material';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';

// Composant UserDialog
const UserDialog = ({ open, onClose, userId }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Animation for dialog entrance
  const animation = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0.9)',
    config: { tension: 300, friction: 30 }
  });

  useEffect(() => {
    if (userId) {
      // Appel à l'API pour récupérer les informations de l'utilisateur
      axios.get(`https://api.datalgeria.com/users/user?user_id=${userId}`)
        .then(response => {
          setUserData(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          setLoading(false);
        });
    }
  }, [userId]);

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  // Si les données utilisateur ne sont pas disponibles
  if (!userData) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography variant="h6" color="error">Utilisateur non trouvé</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  // Destructurer les données utilisateur et du club
  const { username, email, picture, clubInfo } = userData;

  const xp = clubInfo?.xp || 0;
  // Calcul du niveau XP et de la progression
  const level = Math.floor(xp / 1000); // Niveau actuel (par exemple, 6500 xp -> niveau 6)
  const xpInProgress = xp % 1000; // XP restant pour compléter le niveau
  const progress = xpInProgress / 1000; // Progression en pourcentage

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <animated.div style={animation}>
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={picture} alt={username} style={{ borderRadius: '50%', width: 50, height: 50, marginRight: 15 }} />
            <Typography variant="h6">{username}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Affichage des informations utilisateur */}
            <Typography variant="body1">Nom : {clubInfo?.name} {clubInfo?.fname}</Typography>
            <Typography variant="body1">Username : {username}</Typography>
            <Typography variant="body1">Email (Club) : {clubInfo?.email}</Typography>

            {/* Affichage des badges */}
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {clubInfo?.is_president ? <Chip label="Président" color="primary" /> : null}

{clubInfo?.is_vp ? <Chip label="Vice-Président" color="secondary" /> : null}

 {clubInfo?.is_intern ? <Chip label="Stagiaire" color="info" /> : null}

  {clubInfo?.roles?.includes('teacher') ? <Chip label="Enseignant" color="warning" /> : null}
            </Box>

            {/* Affichage du niveau XP */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">Niveau XP du Club : {level} (XP total : {xp})</Typography>
              <LinearProgress variant="determinate" value={progress * 100} sx={{ marginTop: 1 }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography variant="caption" color="textSecondary">XP restant : {1000 - xpInProgress} XP</Typography>
                <Typography variant="caption" color="textSecondary">Prochain niveau</Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Fermer</Button>
        </DialogActions>
      </animated.div>
    </Dialog>
  );
};

export default UserDialog;
