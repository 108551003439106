import React, { useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS } from './editorTools';

const EditorContent = forwardRef(({ onContentChange }, ref) => {
  const editorCore = useRef(null);
  const ReactEditorJS = createReactEditorJS();

  // Initialize editor instance and set reference
  const handleInitialize = useCallback((instance) => {
    instance._editorJS.isReady
      .then(() => {
        editorCore.current = instance; // Set editor reference
      })
      .catch((err) => console.log("An error occurred", err));
  }, []);

  // Function to save editor content and return the JSON output
  const handleSaveEditorContent = async () => {
    if (editorCore.current) {
      try {
        const outputData = await editorCore.current.save();
        onContentChange(outputData); // Send the output to the parent component
        return outputData;
      } catch (error) {
        console.error('Saving editor content failed: ', error);
      }
    }
    return null;
  };

  // Expose the handleSaveEditorContent function to the parent component
  useImperativeHandle(ref, () => ({
    handleSaveEditorContent,
  }));

  return (
    <div>
      <ReactEditorJS
        onInitialize={handleInitialize} // Use handleInitialize for setup
        tools={EDITOR_JS_TOOLS}
        placeholder="Write your description here..."
      />
    </div>
  );
});

export default EditorContent;
