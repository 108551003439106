import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { createFolder } from '../api';

const CreateFolderDialog = ({ projectId, parentFolder, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const handleCreate = async () => {
    try {
      await createFolder(projectId, name, parentFolder, null);
      onSuccess();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Créer un Dossier
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Créer un Dossier</DialogTitle>
        <DialogContent>
          <TextField fullWidth label="Nom du Dossier" value={name} onChange={(e) => setName(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button onClick={handleCreate} variant="contained">
            Créer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateFolderDialog;
