import React from 'react';
import { Box, Avatar, Typography, IconButton } from '@mui/material';

const UserItem = ({ user, isSelected, onSelect }) => {

    
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 10px',
        cursor: 'pointer',
        border: isSelected ? '2px solid #1976d2' : '2px solid transparent', // Highlight border for selected user
        padding: '5px',
        borderRadius: '8px',
        '&:hover': {
          border: '2px solid #1976d2', // Highlight on hover
        },
      }}
      onClick={() => onSelect(user.id)} // Call onSelect with user's ID
    >
      <Avatar src={user.picture} alt={user.name} sx={{ width: 56, height: 56 }} />
      <Typography variant="caption">{user.name}</Typography>
    </Box>
  );
};

export default UserItem;
