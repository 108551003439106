// ScrollArrow.jsx
import React from 'react';
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const ScrollArrow = ({ direction, show, onClick }) => {
  if (!show) return null;

  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        [direction === 'left' ? 'left' : 'right']: -20,
        zIndex: 2,
        bgcolor: 'background.paper',
        boxShadow: 2,
        '&:hover': { bgcolor: 'background.default' },
      }}
    >
      {direction === 'left' ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  );
};



export default ScrollArrow;