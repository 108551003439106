import React from 'react';
import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import { Folder, InsertDriveFile, Delete, Edit } from '@mui/icons-material';
import { motion } from 'framer-motion';
import RenameDialog from './RenameDialog';
import DeleteDialog from './DeleteDialog';

const FileFolderList = ({ items, onRefresh, onFolderClick }) => (
  <List>
    {items.map((item) => (
      <motion.div
        key={item.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.3 }}
      >
        <ListItem divider>
          {item.item_type === 'folder' ? (
            <IconButton onClick={() => onFolderClick(item.id)}> 
              <Folder />
            </IconButton>
          ) : (
            <InsertDriveFile />
          )}
          <ListItemText 
            primary={item.name} 
            secondary={item.item_type === 'file' ? item.file_extension : ''} 
          />
          <RenameDialog item={item} onRefresh={onRefresh} />
          <DeleteDialog item={item} onRefresh={onRefresh} />
        </ListItem>
      </motion.div>
    ))}
  </List>
);

export default FileFolderList;
