import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { deleteItem } from '../api';

const DeleteDialog = ({ item, onRefresh }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteItem(item.id, item.item_type);
      onRefresh();  // Refresh the list after deletion
      setOpen(false);
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={() => setOpen(true)}
        startIcon={<DeleteIcon />}
      >
        Supprimer
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Supprimer {item.item_type === 'folder' ? 'le Dossier' : 'le Fichier'}</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer {item.item_type === 'folder' ? 'ce Dossier' : 'ce Fichier'} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Annuler
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            disabled={loading}
          >
            {loading ? 'Suppression...' : 'Supprimer'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
