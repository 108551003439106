import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AddCircleOutline, DeleteOutline, InfoOutlined, CategoryOutlined } from '@mui/icons-material';
import axios from 'axios'; // Ensure axios is installed

const AddDataset = () => {
  const [datasetName, setDatasetName] = useState('');
  const [description, setDescription] = useState('');
  const [columns, setColumns] = useState([]);
  const [addUserId, setAddUserId] = useState(false);
  const [addValidation, setAddValidation] = useState(false);
  const [addDateTime, setAddDateTime] = useState(false); // State for date time checkbox
  const [openModal, setOpenModal] = useState(false);
  const [currentColumnIndex, setCurrentColumnIndex] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [tempValue, setTempValue] = useState('');
  const [primaryKeys, setPrimaryKeys] = useState([]); // Updated state for primary keys

  // Predefined data types
  const dataTypes = ['VARCHAR', 'INT', 'FLOAT', 'BOOLEAN', 'DATE'];

  // Handle opening and closing the modal
  const handleOpenModal = (index, type) => {
    setCurrentColumnIndex(index);
    setModalType(type);
    setTempValue(type === 'type' ? columns[index].type : columns[index].description);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  // Add an empty column
  const addColumn = () => {
    setColumns([...columns, { name: '', type: '', description: '' }]);
  };

  // Delete a column
  const deleteColumn = (index) => {
    setColumns(columns.filter((_, i) => i !== index));
  };

  // Update values after modal validation
  const handleSaveModal = () => {
    const updatedColumns = [...columns];
    updatedColumns[currentColumnIndex][modalType] = tempValue;
    setColumns(updatedColumns);
    handleCloseModal();
  };

  // Handle changes in each column field
  const handleColumnChange = (index, field, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index][field] = value;
    setColumns(updatedColumns);
  };

  // Handle primary key changes
  const handlePrimaryKeyChange = (columnName) => {
    setPrimaryKeys((prev) =>
      prev.includes(columnName)
        ? prev.filter((pk) => pk !== columnName)
        : [...prev, columnName]
    );
  };

  // Submit the dataset
  const handleSubmit = async () => {
    const structure = columns.map(col => ({
      name: col.name,
      type: col.type,
      description: col.description,
    }));

    // Add additional columns based on checkboxes
    if (addUserId) {
      structure.push({ name: 'user_id', type: 'INT', description: 'User ID' });
    }

    if (addValidation) {
      structure.push({ name: 'is_valid', type: 'BOOLEAN', description: 'Validation status' });
    }

    // Add datetime column if checkbox is checked
    if (addDateTime) {
      structure.push({ name: 'add_datetime', type: 'DATETIME', description: 'Date and time added' });
    }

    const dataset = {
      name: datasetName,
      description,
      primaryKeys, // Add primary keys
      structure,
      addUserId,
      addValidation,
      addDateTime, // Include addDateTime in the dataset
    };

    try {
      console.log("Sending data to API:", JSON.stringify(dataset)); 
      const response = await axios.post('https://api.datalgeria.com/datasets/new_dataset', dataset); // Send data to API
      console.log("Response from API:", response.data);
    } catch (error) {
      console.error("Error while sending data:", error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
        <Typography variant="h4" gutterBottom>
          Créer un nouveau Dataset
        </Typography>

        {/* Basic information */}
        <TextField
          label="Nom du Dataset"
          variant="outlined"
          fullWidth
          margin="normal"
          value={datasetName}
          onChange={(e) => setDatasetName(e.target.value)}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {/* Select primary key */}
        <Typography variant="h6">Sélectionner une clé primaire</Typography>
        {columns.map((column, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={primaryKeys.includes(column.name)}
                onChange={() => handlePrimaryKeyChange(column.name)}
              />
            }
            label={column.name}
          />
        ))}

        {/* Additional options */}
        <Box my={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox 
                  checked={addUserId} 
                  onChange={(e) => setAddUserId(e.target.checked)} 
                />
              }
              label="Ajouter user_id à chaque ligne"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={addValidation} 
                  onChange={(e) => setAddValidation(e.target.checked)} 
                />
              }
              label="Ajouter une validation (is_valid)"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={addDateTime} 
                  onChange={(e) => setAddDateTime(e.target.checked)} 
                />
              }
              label="Ajouter date time"
            />
          </Box>
        </Box>

        {/* Column table */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          my={3}
          sx={{ overflowX: 'auto', whiteSpace: 'nowrap', paddingBottom: 2 }}
        >
          {columns.map((column, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              mx={1}
              p={1}
              sx={{
                minWidth: 150,
                border: '1px solid #ddd',
                borderRadius: 2,
                backgroundColor: '#f9f9f9',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <TextField
                placeholder="Nom"
                variant="outlined"
                fullWidth
                size="small"
                value={column.name}
                onChange={(e) => handleColumnChange(index, 'name', e.target.value)}
                sx={{ mb: 1 }}
              />
              <Box display="flex" flexDirection="row" alignItems="center">
                <Tooltip title="Sélectionner un type de données">
                  <IconButton onClick={() => handleOpenModal(index, 'type')}>
                    <CategoryOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ajouter une description">
                  <IconButton onClick={() => handleOpenModal(index, 'description')}>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={() => deleteColumn(index)} color="error">
                  <DeleteOutline />
                </IconButton>
              </Box>
            </Box>
          ))}
          {/* Add column button */}
          <IconButton onClick={addColumn} color="primary" sx={{ ml: 2 }}>
            <AddCircleOutline fontSize="large" />
          </IconButton>
        </Box>

        {/* Submit button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 3 }}
        >
          Créer le Dataset
        </Button>
      </Paper>

      {/* Modal for data type or description */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 3,
            width: '300px',
          }}
        >
          <Typography variant="h6" mb={2}>
            {modalType === 'type' ? 'Sélectionner le type de données' : 'Ajouter une description'}
          </Typography>
          {modalType === 'type' ? (
            <Select
              fullWidth
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
            >
              {dataTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
            />
          )}
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={handleCloseModal}>Annuler</Button>
            <Button onClick={handleSaveModal} color="primary" sx={{ ml: 1 }}>
              Sauvegarder
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default AddDataset;
