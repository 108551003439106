import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Return = ({ projectId, parentFolder, folderId }) => {

    const navigate = useNavigate();

    const returnToParent = () => {
       if (parentFolder) {
         navigate(`/dashboard/project/${projectId}?page=files&folderId=${parentFolder}`);
       } else {
            navigate(`/dashboard/project/${projectId}?page=files`);
        }

    }




  return (
    <>
    {folderId &&
      <Button variant="outlined" onClick={() =>  returnToParent()}> 
        Retour
      </Button>
    }


    </>
  );
};

export default Return;
