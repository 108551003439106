// Section.jsx
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Typography, IconButton } from '@mui/material';
import { Add as AddIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import Task from './Task';

const Section = ({ section, index, onAddTask, projectData }) => (
  <Draggable draggableId={section.id} index={index}>
    {(provided) => (
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        sx={{
          width: 300,
          bgcolor: 'grey.100',
          borderRadius: 1,
          p: 2,
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
          {...provided.dragHandleProps}
        >
          <Typography variant="h6">
            {section.name} ({section.tasks.length})
          </Typography>
          <Box>
            <IconButton size="small" onClick={onAddTask}>
              <AddIcon />
            </IconButton>
            <IconButton size="small">
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>

        <Droppable droppableId={section.id} type="task">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ minHeight: 100 }}
            >
              {section.tasks.map((task, index) => (
                <Task 
                projectData={projectData}
                key={task.id} task={task} index={index} />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    )}
  </Draggable>
);


export default Section;