import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  TextField,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { fade } from '@mui/material/styles';

// Styled components for better design
const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '8px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

const SearchField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    transition: 'border-color 0.3s',
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
  },
}));

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PAGE_SIZE = 10;
  const authToken = localStorage.getItem('authToken');

  const fetchProjects = async () => {
    const response = await axios.get(`https://api.datalgeria.com/club/projects`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page,
        search
      }
    });

    setProjects(response.data.projects);
    setTotal(response.data.total);
  };

  useEffect(() => {
    fetchProjects();
  }, [page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 when a new search is initiated
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
        Projects
      </Typography>

      {/* Search bar */}
      <SearchField
        label="Search Projects"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Nom du Projet</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow key={project.id} hover>
                <TableCell>

                <Box
            sx={{
              display: 'inline-block',
              padding: '8px 12px',
              borderRadius: '16px',
              backgroundColor: '#e0f7fa', // Light blue background for the tag
              color: '#00796b', // Darker text color
              fontWeight: 'bold',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)', // Slightly enlarge on hover
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Add shadow on hover
              },
            }}
          >
            {project.id}
          </Box>

                </TableCell>
                <TableCell>{project.name}</TableCell>
                <TableCell>
                  <Button 
                    component={Link} 
                    to={`/dashboard/project/${project.id}`} 
                    variant="contained" 
                    color="primary"
                    style={{ transition: 'background-color 0.3s' }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#1976d2"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ""}
                  >
                    Select
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Pagination
        count={Math.ceil(total / PAGE_SIZE)}
        page={page}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </StyledBox>
  );
};

export default Projects;
