import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { uploadFile } from '../api';

const UploadFileDialog = ({ projectId,  parentFolder, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleUpload = async () => {
    if (file) {
      try {
        await uploadFile(projectId, parentFolder, file, file.name, null);
        onSuccess();
        setOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Télécharger un Fichier
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Télécharger un Fichier</DialogTitle>
        <DialogContent>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button onClick={handleUpload} variant="contained">
            Télécharger
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFileDialog;
