import React from 'react';
import { Box, Typography, Container, Grid, IconButton, Link } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Phone, Email } from '@mui/icons-material';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const socialLinks = [
    { icon: <Facebook />, url: 'https://facebook.com/itrc.club', label: 'Facebook' },
    { icon: <Instagram />, url: 'https://instagram.com/itrc.club', label: 'Instagram' },
    { icon: <LinkedIn />, url: 'https://linkedin.com/company/itrc-club', label: 'LinkedIn' }
  ];

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#1a1a1a',
        color: 'white',
        py: 6,
        position: 'relative',
        mt: 'auto'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* À propos */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              ITRC Club
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, color: '#rgba(255,255,255,0.7)' }}>
              Club Scientifique Inter-Universitaire dédié à l'innovation et au partage des connaissances.
            </Typography>
          </Grid>

          {/* Contact */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Contact
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Phone sx={{ mr: 1, fontSize: '1.2rem', color: '#rgba(255,255,255,0.7)' }} />
              <Typography variant="body2" sx={{ color: '#rgba(255,255,255,0.7)' }}>
                +213 667 868 834
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Email sx={{ mr: 1, fontSize: '1.2rem', color: '#rgba(255,255,255,0.7)' }} />
              <Typography variant="body2" sx={{ color: '#rgba(255,255,255,0.7)' }}>
                contact@itrc.club
              </Typography>
            </Box>
          </Grid>

          {/* Réseaux sociaux */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Suivez-nous
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {socialLinks.map((social) => (
                <IconButton
                  key={social.label}
                  component="a"
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255,0.1)',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.3s ease'
                    }
                  }}
                  aria-label={social.label}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Typography 
          variant="body2" 
          align="center" 
          sx={{ 
            mt: 4, 
            pt: 3, 
            borderTop: '1px solid rgba(255,255,255,0.1)',
            color: '#rgba(255,255,255,0.7)'
          }}
        >
          © {currentYear} ITRC Club. Tous droits réservés.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;