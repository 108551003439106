import React from 'react';
import { Box } from '@mui/material';

const Card2 = ({ content, sx }) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        background: '#0a202d',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Space between and content
        ...sx, // Allow additional styles via sx prop
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center', // Center the content horizontally
          alignItems: 'center', // Center the content vertically
          flexGrow: 1, // Allow this box to take remaining space
        }}
      >
        {content}
      </Box>
    </Box>
  );
};

export default Card2;
