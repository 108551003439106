// Sidebar.js
import React from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const Sidebar = ({ setPage, setSelectedStep, setSelectedStepName, setSelectedStepUsers, allOpen, handleToggleAll, projectData, openSections, handleStepClick, handleToggle, handleOpenModal, loading }) => {

 
  return (
    <Box sx={{ width: 240, borderRight: '1px solid #ddd', backgroundColor: '#f5f5f5', overflowY: 'auto', maxHeight: '100vh' }}>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: '#0a202d' }}>
        <Toolbar>
          <ListItemText primary="Project Menu" />
        </Toolbar>
      </AppBar>
      
      <List sx={{ opacity: loading ? 0.2 : 1 }}>
        
        {/* Overview Section */}
        <ListItem button onClick={() => setPage('overview')}>
          <ListItemText primary="Overview" />
        </ListItem>

        {/* Resources Section */}
        <ListItem button onClick={() => setPage('resources')}>
          <ListItemText primary="Ressources" />
        </ListItem>

        {/* Project Management Section */}
        <ListItem button onClick={handleToggleAll}>
          <ListItemText primary="Project Management" />
          <ExpandMoreIcon style={{ transform: allOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
        </ListItem>

        <Collapse in={allOpen} timeout="auto" unmountOnExit>
          {projectData.sections.map((section, titleIndex) => (
            <React.Fragment key={titleIndex}>
              <ListItem button onClick={() => handleToggle(titleIndex)} sx={{ pl: 2 }}>
                <ListItemText primary={section.name} />
                <IconButton onClick={(e) => { e.stopPropagation(); handleOpenModal('subtitle', section.id, section.users); }} size="small" color="primary">
                  <AddIcon fontSize="small" />
                </IconButton>
                <ExpandMoreIcon style={{ transform: openSections[titleIndex] ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              </ListItem>
              
              <Collapse in={openSections[titleIndex]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {section.steps.map((step, stepIndex) => (
                    <ListItem 
                      key={stepIndex}
                      button
                      onClick={() => {
                        setPage('tasks');
                        setSelectedStep(step.id);
                        setSelectedStepName(step.name);
                        setSelectedStepUsers(step.users);
                      }}
                      sx={{ pl: 4 }}
                    >
                      <CheckCircleIcon sx={{ mr: 1 }} />
                      <ListItemText primary={step.name} primaryTypographyProps={{ variant: 'body2' }} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
          <IconButton onClick={() => handleOpenModal('title')} color="primary" sx={{ mt: 2 }}>
            <AddIcon />
          </IconButton>
        </Collapse>

        {/* File Management Section */}
        <ListItem button onClick={() => setPage('files')}>
          <ListItemText primary="File Management" />
        </ListItem>

      </List>
    </Box>
  );
};

export default Sidebar;
