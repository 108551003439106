import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import EditorContent from './EditorContent'; // Import the EditorContent component
import UserSelect from './UserSelect'; // Import UserSelect component

const AddItemModal = ({ modalOpen, handleModalClose, modalType, users, newItemText, setNewItemText, handleSaveItem, projectId, RefreshSideBar }) => {
    const [title, setTitle] = useState(''); // State to hold the title
  const [description, setDescription] = useState(null); // State for editor content
  const [selectedUserIds, setSelectedUserIds] = useState([]); // State for selected user IDs
  const editorRef = useRef(null); // Reference to the EditorContent component

  const [stepUsers, setStepUsers] = useState([]);

  useEffect(() => {
      if (Array.isArray(users) && Array.isArray(modalType.users)) {
          // Filter users based on whether they are present in modalType.users
          const filteredUsers = users.filter(user => 
              modalType.users.some(selectedUser => selectedUser.user_id === user.id)
          );
  
          // Set the filtered users to stepUsers
          setStepUsers(filteredUsers);
  
          // Debug logs
          console.log('Selected Step Users:', modalType.users);
          console.log('Users in AddItemModal:', JSON.stringify(users));
          console.log('Filtered Step Users:', filteredUsers);
          setStepUsers(filteredUsers);
      }
  }, [modalType.users, users]); // Add users and selectedStepUsers as dependencies
  
  // Function to handle saving the item with title, description, and selected users
  const handleSaveItemWithEditor = async () => {
    let editorOutput = null;
  
    // Get the editor's JSON output if editorRef is set
    if (editorRef.current) {
      editorOutput = await editorRef.current.handleSaveEditorContent();
    }
  
    // Determine endpoint and id based on modalType
    const endpoint =
      modalType.type === 'title'
        ? 'https://api.datalgeria.com/project/section'
        : 'https://api.datalgeria.com/project/step';
  
    const newItem = {
      id: modalType.type === 'title' ? projectId : modalType.titleIndex,
      name: title, // Title field
      description: editorOutput ? JSON.stringify(editorOutput) : null, // JSON stringified editor output
      userIds: selectedUserIds, // Array of selected user IDs
    };
  
    console.log('New Item:', newItem); // Log the new item to check the structure

    try {
      const response = await fetch(endpoint, {
        method: 'POST', // Specify the request method
        headers: {
          'Content-Type': 'application/json', // Specify the content type
        },
        body: JSON.stringify(newItem), // Convert the newItem object to JSON
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json(); // Parse the JSON response
      console.log('Item saved successfully:', result); // Log the result or handle it as needed
    } catch (error) {
      console.error('Error saving item:', error); // Handle any errors
    } finally {
        handleModalClose(); // Close the modal after saving the
        RefreshSideBar(); // Trigger sidebar refresh after adding item
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Add New {modalType.type === 'title' ? 'Section' : 'Step'}
      </DialogTitle>
      <DialogContent>
        {/* Input field for the title */}
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={title} // Use the title state
          onChange={(e) => setTitle(e.target.value)} // Update title state on change
        />
        {/* User selection component */}
        <UserSelect
          users={ modalType.type === 'title' ? users : stepUsers }
          selectedUserIds={selectedUserIds}
          setSelectedUserIds={setSelectedUserIds}
        />
        <Divider sx={{ marginY: 3 }} />
        <h3>Description</h3>
        {/* Editor for the description */}
        <EditorContent
          ref={editorRef} // Reference to EditorContent component
          onContentChange={setDescription} // Set description when content changes
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button onClick={handleSaveItemWithEditor} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemModal;
