import React from 'react';
import { Avatar, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';

// Gradient progress bar
const GradientProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: '#e0e0e0',
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(90deg, #f2709c, #ff9472)',
    animation: 'progress 2s ease-in-out forwards',
  },
  '@keyframes progress': {
    '0%': { width: '0%' },
    '100%': { width: '100%' },
  },
}));

// Badge-style level with SVG
const LevelBadge = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 30,
  height: 30,
  background: 'linear-gradient(135deg, #43cea2, #185a9d)',
  borderRadius: '50%',
  color: '#fff',
  fontSize: 9,
  fontWeight: 'bold',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: '50%',
    background: 'linear-gradient(135deg, #ff9a9e, #fad0c4)',
    opacity: 0.2,
    zIndex: -1,
  },
  animation: 'badge-pulse 2s infinite ease-in-out',
  '@keyframes badge-pulse': {
    '0%, 100%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.1)' },
  },
}));

const ProfileSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderBottom: '1px solid #e0e0e0',
  gap: theme.spacing(2),
  '& .profile-name': {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#444',
    letterSpacing: '0.5px',
  },
}));

const ProfileDetails = ({ visible, profile }) => {
  return (
    <ProfileSection
    sx={{
      display: !visible ? 'flex' : 'none',
    }}
    >
      <Avatar
        src={profile.picture}
        alt="Profile Picture"
        sx={{
          width: 64,
          height: 64,
          border: '2px solid #fff',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        }}
      />

      <Typography className="profile-name">
        {profile.name} {profile.fname}
      </Typography>

      <Box display="flex" alignItems="center" flexDirection="column" sx={{ width: '100%' }}>
  

        <Box mt={2} sx={{ width: '100%' }}>
          <Box display="flex" alignItems="center" justifyContent="space-around" flexDirection="row" marginBottom="5px" sx={{ width: '100%' }}>
          <LevelBadge>
          Lvl {profile.level}
        </LevelBadge>
          <Typography variant="body2" color="textSecondary" textAlign="center">
            {profile.remainingXp}/1000 XP
          </Typography>
          </Box>

          <GradientProgress
            variant="determinate"
            value={(profile.remainingXp / 1000) * 100}
          />
        </Box>
      </Box>
    </ProfileSection>
  );
};

export default ProfileDetails;
