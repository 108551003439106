import React, { useRef } from 'react';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  IconButton,
} from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material'; // Material Icons for arrows
import FilesManager from '../Files/FilesManager';
import TasksComponent from './TasksComponent'; // Import the TasksComponent
// Custom styles for the user and university items
const UserItem = ({ user, onClick }) => (
  <Box
    onClick={onClick}
    sx={{ textAlign: 'center', margin: '0 10px' }}
  >
    <Avatar
      src={user.picture}
      alt={`${user.fname} ${user.name}`}
      sx={{
        width: 80,
        height: 80,
        margin: '0 auto', // Center the avatar horizontally
      }}
    />
    <Typography variant="body2" sx={{ textAlign: 'center' }}>{user.fname} {user.name}</Typography>
  </Box>
);

const UniversityItem = ({ university }) => (
    <Box
      sx={{
        textAlign: 'center',
        margin: '0 10px',
        padding: '10px 15px',
        borderRadius: '16px',
        backgroundColor: '#e0f7fa', // Light blue background
        transition: 'transform 0.3s, box-shadow 0.3s', // Animation for hover effects
        '&:hover': {
          transform: 'scale(1.05)', // Slightly enlarge on hover
        },
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#00796b' }}>
        {university.name}
      </Typography>
    </Box>
  );
  
const MainContent = ({ projectId, page, selectedStep, selectedStepUsers, selectedStepName, projectData, setSelectedUserId }) => {
  const users = projectData?.users || [];
  const universities = projectData?.universities || [];
  
  const userListRef = useRef(null);
  const universityListRef = useRef(null);

  const scroll = (ref, scrollAmount) => {
    if (ref.current) {
      ref.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3, backgroundColor: '#ffffff', overflowY: 'auto', width: '50%' }}>
      {page === 'tasks' ? (
        <TasksComponent stepId={selectedStep}
            stepName={selectedStepName}
            users={selectedStepUsers}
            projectData={projectData}
        />
      ) : page === 'files' ? (
        <>
          <FilesManager projectId={projectId} />
          
       
        </>
      ) : page === 'overview' ? (
        <>
          <Typography
            variant="h4" gutterBottom>
            Overview for project <b>{projectData?.project_details?.name}</b>
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
              padding: '8px 12px',
              borderRadius: '16px',
              backgroundColor: '#e0f7fa', // Light blue background for the tag
              color: '#00796b', // Darker text color
              fontWeight: 'bold',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)', // Slightly enlarge on hover
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Add shadow on hover
              },
            }}
          >
            {projectData?.project_details?.id}
          </Box>
          <Divider sx={{ marginY: 3 }} />

          {/* Users Section */}
          <Typography variant="h5" gutterBottom>
            Users
          </Typography>
          <Box sx={{
            position: 'relative', zIndex: 1,
            display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => scroll(userListRef, -200)}><ArrowBack /></IconButton>
            <Box ref={userListRef} sx={{ display: 'flex', overflowX: 'hidden', scrollBehavior: 'smooth', whiteSpace: 'nowrap', flexGrow: 1 }}>
              {users.length > 0 ? (
                users.map((user) => (
                  <UserItem 
                  onClick={() => setSelectedUserId(user.id)}
                  key={user.id} user={user} />
                ))
              ) : (
                <Typography variant="body2">No users available</Typography>
              )}
            </Box>
            <IconButton onClick={() => scroll(userListRef, 200)}><ArrowForward /></IconButton>
          </Box>

          <Divider sx={{ marginY: 3 }} />

          {/* Universities Section */}
          <Typography variant="h5" gutterBottom>
            Universities
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => scroll(universityListRef, -200)}><ArrowBack /></IconButton>
            <Box ref={universityListRef} sx={{ display: 'flex', overflowX: 'hidden', scrollBehavior: 'smooth', whiteSpace: 'nowrap', flexGrow: 1 }}>
              {universities.length > 0 ? (
                universities.map((university) => (
                  <UniversityItem key={university.id} university={university} />
                ))
              ) : (
                <Typography variant="body2">No universities available</Typography>
              )}
            </Box>
            <IconButton onClick={() => scroll(universityListRef, 200)}><ArrowForward /></IconButton>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default MainContent;
