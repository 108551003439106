import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import UserItem from './UserItem'; // Import the UserItem component

const UserSelect = ({ users, selectedUserIds, setSelectedUserIds }) => {
  const handleSelectUser = (userId) => {
    setSelectedUserIds((prevSelected) => {
      // Toggle selection: if already selected, remove it; otherwise, add it
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'auto', padding: '10px' }}>
      <Typography variant="subtitle1" sx={{ marginRight: '10px' }}>
        Select Users:
      </Typography>
      {users.length > 0 ? (
        users.map((user) => (
          <UserItem
            key={user.id}
            user={user}
            isSelected={selectedUserIds.includes(user.id)} // Check if the user is selected
            onSelect={handleSelectUser} // Pass the select handler
          />
        ))
      ) : (
        <Typography>No users available</Typography>
      )}
    </Box>
  );
};

export default UserSelect;
