import React, { useRef, useState, useCallback, useEffect } from 'react';
import { 
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
  Paper,
  Snackbar,
  Alert
} from '@mui/material';
import { 
  AutoFixHigh as WandIcon,
  Close as CloseIcon,
  Image as ImageIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS } from './editorTools';

const EditorJS = createReactEditorJS();

const ArticleEditor = () => {
  const editorCore = useRef(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [editorData, setEditorData] = useState({
    time: Date.now(),
    blocks: [
      {
        type: "paragraph",
        data: {
          text: "Start writing your article..."
        }
      }
    ],
    version: '2.22.2'
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Fonction pour initialiser l'éditeur
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  // Effet pour mettre à jour l'éditeur quand editorData change
  useEffect(() => {
    const updateEditor = async () => {
      if (editorCore.current) {
        try {
          // Instead of clearing the editor, we will just update its content.
          await editorCore.current.render(editorData);
        } catch (err) {
          console.error('Error updating editor:', err);
        }
      }
    };
    updateEditor();
  }, [editorData]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCoverImage(URL.createObjectURL(file));
    }
  };

  const generateArticle = async () => {
    if (!title) {
      setSnackbar({ 
        open: true, 
        message: 'Please enter a title first', 
        severity: 'warning' 
      });
      return;
    }
    
    setIsGenerating(true);
    try {
      const response = await fetch('https://api.datalgeria.com/cm/article', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ title })
      });
      
      const data = await response.json();
      
      if (data) {
        setDescription(data.description);
        // Mise à jour de l'état avec les nouvelles données
        const newEditorData = {
          time: Date.now(),
          blocks: data.content,
          version: '2.22.2'
        };
        setEditorData(newEditorData);

        setSnackbar({ 
          open: true, 
          message: 'Article generated successfully!', 
          severity: 'success' 
        });
      }
    } catch (error) {
      console.error('Generation error:', error);
      setSnackbar({ 
        open: true, 
        message: 'Error generating article', 
        severity: 'error' 
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async () => {
    try {
      const savedData = await editorCore.current.save();
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('content', JSON.stringify(savedData));
      if (coverImage) formData.append('coverImage', coverImage);

      const response = await fetch('/api/articles', {
        method: 'POST',
        body: formData,
      });
      
      if (response.ok) {
        setSnackbar({ 
          open: true, 
          message: 'Article saved successfully!', 
          severity: 'success' 
        });
      }
    } catch (error) {
      console.error('Save error:', error);
      setSnackbar({ 
        open: true, 
        message: 'Error saving article', 
        severity: 'error' 
      });
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 3 }}>
      <Card elevation={3}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
              Create New Article
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<WandIcon />}
                onClick={generateArticle}
                disabled={isGenerating || !title}
                sx={{ mr: 2 }}
              >
                {isGenerating ? <CircularProgress size={24} /> : 'Generate'}
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>

          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 3 }}
          />

          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 3 }}
          />

          <Paper
            variant="outlined"
            sx={{
              p: 3,
              mb: 3,
              cursor: 'pointer',
              textAlign: 'center',
              bgcolor: 'background.default'
            }}
            onClick={() => document.getElementById('image-upload').click()}
          >
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageUpload}
            />
            {coverImage ? (
              <Box sx={{ position: 'relative' }}>
                <img
                  src={coverImage}
                  alt="Cover"
                  style={{ maxWidth: '100%', maxHeight: 300, objectFit: 'cover' }}
                />
                <IconButton
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCoverImage(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ py: 4 }}>
                <ImageIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                <Typography color="text.secondary">
                  Click or drag to upload cover image
                </Typography>
              </Box>
            )}
          </Paper>

          <Paper variant="outlined" sx={{ p: 2 }}>
            <EditorJS
              onInitialize={handleInitialize}
              tools={EDITOR_JS_TOOLS}
              defaultValue={editorData}
              onChange={async () => {
                const content = await editorCore.current.save();
                setEditorData(content);
              }}
            />
          </Paper>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ArticleEditor;
