// AddTaskModal.jsx
import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import EditorContent from './EditorContent'; // Import the EditorContent component
import UserSelect from './UserSelect'; // Import UserSelect component

const AddTaskModal = ({ modalOpen, handleModalClose, users, columnId, RefreshStep }) => {
  const [title, setTitle] = useState(''); // State to hold the title
  const [description, setDescription] = useState(null); // State for editor content
  const [selectedUserIds, setSelectedUserIds] = useState([]); // State for selected user IDs
  const editorRef = useRef(null); // Reference to the EditorContent component

  // Function to handle saving the section with title, description, and selected users
  const handleSaveSection = async () => {
    let editorOutput = null;

    // Get the editor's JSON output if editorRef is set
    if (editorRef.current) {
      editorOutput = await editorRef.current.handleSaveEditorContent();
    }

    const newSection = {
      columnId,
      name: title, // Title field
      description: editorOutput ? JSON.stringify(editorOutput) : null, // JSON stringified editor output
      userIds: selectedUserIds, // Array of selected user IDs
    };

    console.log('New Section:', newSection); // Log the new section to check the structure

    try {
      const response = await fetch('https://api.datalgeria.com/project/tasks', {
        method: 'POST', // Specify the request method
        headers: {
          'Content-Type': 'application/json', // Specify the content type
        },
        body: JSON.stringify(newSection), // Convert the newSection object to JSON
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json(); // Parse the JSON response
      console.log('Section saved successfully:', result); // Log the result or handle it as needed
    } catch (error) {
      console.error('Error saving section:', error); // Handle any errors
    } finally {
      handleModalClose(); // Close the modal after saving
      RefreshStep(); // Trigger sidebar refresh after adding section
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="md" fullWidth>
      <DialogTitle>Add New Task</DialogTitle>
      <DialogContent>
        {/* Input field for the title */}
        <TextField
          autoFocus
          margin="dense"
          label="Section Name"
          type="text"
          fullWidth
          variant="outlined"
          value={title} // Use the title state
          onChange={(e) => setTitle(e.target.value)} // Update title state on change
        />
        {/* User selection component */}
        <UserSelect
          users={users} // Pass the users prop to UserSelect
          selectedUserIds={selectedUserIds}
          setSelectedUserIds={setSelectedUserIds}
        />
        <Divider sx={{ marginY: 3 }} />
        <h3>Description</h3>
        {/* Editor for the description */}
        <EditorContent
          ref={editorRef} // Reference to EditorContent component
          onContentChange={setDescription} // Set description when content changes
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button onClick={handleSaveSection} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTaskModal;
