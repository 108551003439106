import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, CircularProgress, Breadcrumbs, Link } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import FileFolderList from './components/FileFolderList';
import CreateFolderDialog from './components/CreateFolderDialog';
import UploadFileDialog from './components/UploadFileDialog';
import Return from './components/Return';
import { getFilesAndFolders } from './api';

const FilesManager = ({ projectId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [parentFolder, setParentFolder] = useState(null);

  const folderId = searchParams.get('folderId');
  const navigate = useNavigate();

  useEffect(() => {
    fetchFilesAndFolders();
  }, [projectId, folderId, page]);

  const fetchFilesAndFolders = async () => {
    setLoading(true);
    try {
      const data = await getFilesAndFolders(projectId, folderId || null, page);
      setItems(data.items);
      setParentFolder(data.parent_folder);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFolderClick = (folderId) => {
    navigate(`/dashboard/project/${projectId}?page=files&folderId=${folderId}`);
  };

  const breadcrumbs = [
    <Link key="1" color="inherit" onClick={() => navigate(`/dashboard/project/${projectId}?page=files`)}>
      Home
    </Link>,
    parentFolder && (
      <Link key="2" color="inherit" onClick={() => navigate(`/dashboard/project/${projectId}?page=files&folderId=${parentFolder.id}`)}>
        {parentFolder.name}
      </Link>
    ),
    <Typography key="3" color="textPrimary">
      Current Folder
    </Typography>,
  ];

  return (
    <Container>
      <Typography variant="h4" my={4}>
        Gestion des Fichiers et Dossiers
      </Typography>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </motion.div>
      <Box mb={2} display="flex" gap={2}>
        <Return projectId={projectId} parentFolder={parentFolder} folderId={folderId} />
        <CreateFolderDialog projectId={projectId} parentFolder={folderId} onSuccess={fetchFilesAndFolders} />
        <UploadFileDialog parentFolder={folderId} projectId={projectId} onSuccess={fetchFilesAndFolders} />
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <FileFolderList items={items} onRefresh={fetchFilesAndFolders} onFolderClick={handleFolderClick} />
      )}
    </Container>
  );
};

export default FilesManager;
