import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { renameItem } from '../api';

const RenameDialog = ({ item, onRefresh }) => {
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState(item.name);
  const [loading, setLoading] = useState(false);

  const handleRename = async () => {
    setLoading(true);
    try {
      await renameItem(item.id, newName, item.item_type);
      onRefresh();  // Refresh the list after renaming
      setOpen(false);
    } catch (error) {
      console.error("Error renaming item:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Renommer
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Renommer {item.type === 'folder' ? 'le Dossier' : 'le Fichier'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nouveau Nom"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button
            onClick={handleRename}
            variant="contained"
            disabled={loading || newName === item.name}
          >
            {loading ? 'Renommage...' : 'Renommer'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RenameDialog;
