import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import Navbar from './components/global/Navbar';
import Navbar2 from './components/global/Navbar2';
import Home from './routes/Home';
import Signup from './routes/Signup';
import Login from './routes/Login';
import Complete from './routes/Complete';
import Auth from './routes/Auth';
import Confirm from './routes/Confirm';
import RegisterDiscord from "./routes/RegisterDiscord";

import Club from './routes/Club/Club';
import Join from './routes/Club/Join';
import Dashboard from './routes/Club/Dashboard';
import CalendarPage from './routes/Club/Calendar';
import CustomSideBar from './routes/Club/Sidebar';
import ClubValidations from './routes/Club/Validations';
import ClubUniversities from './routes/Club/Universities';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// President Routes
import ClubPresidentProjects from './routes/Club/President/Projects';
import ClubLayout from './routes/Club/ClubLayout';

import Projects from './routes/Club/Projects';
import Project from './routes/Club/Project';
import NewProject from './routes/Club/President/NewProject';
import ProjectSections from './routes/Club/President/ProjectSections';
import SectionSteps from './routes/Club/President/SectionSteps';


import AddDataset from './routes/Club/Datasets/AddDataset';

import ArticleEditor from 'routes/Club/CM/ArticleEditor';
import FilesManager from './routes/Club/Files/FilesManager';
// Dummy authentication function (replace with real auth logic)
const isAuthenticated = () => true;

// PrivateRoute component to protect routes
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

// Custom hook to manage body overflow based on route
const useBodyOverflow = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/club') || location.pathname.startsWith('/console') || location.pathname.startsWith('/join')) {
      document.body.style.overflow = 'hidden'; // Disable body scroll when in club routes
    } else {
      document.body.style.overflow = ''; // Re-enable body scroll for other routes
    }

    return () => {
      document.body.style.overflow = ''; // Clean up on unmount
    };
  }, [location]);
};



// Layout for Public Pages (with Navbar)
const PublicLayout = () => {
  useBodyOverflow();

  const authToken = localStorage.getItem('authToken');
  return (
    <>
      {authToken ? <Navbar2 /> : <Navbar />} {/* Use Navbar2 if authToken exists */}
      <Outlet /> {/* Nested Routes */}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Club />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/complete/:token" element={<Complete />} />
          <Route path="/confirm/:token" element={<Confirm />} />
          <Route path="/auth/:authToken/:username/:email/:picture" element={<Auth />} />
          <Route path="/join" element={<Join />} />
          <Route path="/register-discord" element={<RegisterDiscord />} />

        </Route>

        {/* Club Routes */}
        <Route path="/dashboard/*" element={<PrivateRoute><ClubLayout /></PrivateRoute>}>
          {/* General Club Pages */}
          <Route path="" element={<Dashboard />} />
          <Route path="projects" element={<Projects />} />
          <Route path="project/:projectId" element={<Project />} />
          <Route path="calendar" element={<CalendarPage />} />
          <Route path="validations" element={<ClubValidations />} />
          <Route path="universities" element={<ClubUniversities />} />

          {/* CM pages */}
          <Route path="cm/article-editor" element={<ArticleEditor />} />
          {/* Club President Pages */}
          <Route path="president/projects" element={<ClubPresidentProjects />} />
          <Route path="president/new-project" element={<NewProject />} />
          <Route path="president/project/:projectId/sections" element={<ProjectSections />} />
          <Route path="president/section/:sectionId/steps" element={<SectionSteps />} />
          <Route path="datasets/new-dataset" element={<AddDataset />} />

          <Route path="files-manager/:projectId/:folderId?" element={<FilesManager />} />
        </Route>

     
      </Routes>
    </Router>
  );
};

export default App;
