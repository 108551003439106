import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: theme?.palette?.background?.default || '#fff',
}));

const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const RegisterDiscord = () => {
    const handleRegister = () => {
        // Add your Discord registration logic here
        window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URI&response_type=code&scope=identify';
    };

    return (
        <Root>
            <Title variant="h3">
                Register with Discord
            </Title>
            <StyledButton
                variant="contained"
                color="primary"
                onClick={handleRegister}
            >
                Register with Discord
            </StyledButton>
        </Root>
    );
};

export default RegisterDiscord;
