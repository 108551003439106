import React from 'react';
import './Header1.css'; // Import custom CSS for styles

const Header1 = () => {
    return (
        <div className="header-container">
            <div className="header-content">
                <br/> <br/> <br/>        <br/> <br/> <br/>        <br/> <br/> <br/>
            </div>
            <div className="overlay-bottom"></div>
        </div>
    );
};

export default Header1;
