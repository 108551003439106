import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import Sidebar from './PM/Sidebar';
import MainContent from './PM/MainContent';
import AddItemModal from './PM/AddItemModal';
import "./dashboard.css";
import UserDialog from 'components/dashboard/UserDialog'; // Import the UserDialog component

const Project = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { projectId } = useParams();
  const [openSections, setOpenSections] = useState({});
  const [allOpen, setAllOpen] = useState(false);
  const [projectData, setProjectData] = useState({ project_details: {}, sections: [] });
  const [modalOpen, setModalOpen] = useState(false);
  const [newItemText, setNewItemText] = useState('');
  const [modalType, setModalType] = useState({ type: '', titleIndex: null });
  const [selectedStep, setSelectedStep] = useState(searchParams.get('step') || null);
  const [selectedStepName, setSelectedStepName] = useState(null);
  const [selectedStepUsers, setSelectedStepUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialPage = searchParams.get('page') || 'overview';
  const [page, setPage] = useState(initialPage);  // initialise `page` depuis l'URL

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Mettre à jour l'URL lorsque `page` change via une fonction
  const handlePageChange = (newPage) => {
    if (newPage === 'tasks') {
      return;
    }
    setPage(newPage);
    setSearchParams({ page: newPage });
  };

  const handleStepChange = (step) => {
    // Always set the `page` to 'tasks' when the step is changed.
    setSelectedStep(step);
    setPage('tasks');
    // toggle all
  
    setSearchParams((prevParams) => {
      // Retain the `page` value, but ensure `step` is updated
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set('page', 'tasks');
      updatedParams.set('step', step);  // Ensure `step` is always set
      return updatedParams;
    });
  };
  

  const handleOpenDialog = (userId) => {
    setSelectedUserId(userId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  useEffect(() => {
    if (selectedUserId) {
      handleOpenDialog(selectedUserId);
    }
  }, [selectedUserId]);

  const fetchProjectData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.datalgeria.com/project/sections?id=${projectId}`);
      const data = await response.json();
      if (data.success) {
        setProjectData(data);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);

  const RefreshSideBar = () => {
    fetchProjectData();
  };

  const handleToggle = (titleIndex) => {
    setOpenSections((prev) => ({ ...prev, [titleIndex]: !prev[titleIndex] }));
  };

  const handleToggleAll = () => {
    const newOpenState = !allOpen;
    setAllOpen(newOpenState);
    const updatedSections = {};
    projectData.sections.forEach((_, index) => {
      updatedSections[index] = newOpenState;
    });
    setOpenSections(updatedSections);
  };

  const handleOpenModal = (type, titleIndex = null, users = null) => {
    setModalType({ type, titleIndex, users });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setNewItemText('');
  };

  const handleSaveItem = () => {
    if (modalType.type === 'title') {
      setProjectData((prev) => ({
        ...prev,
        sections: [...prev.sections, { title: newItemText, steps: [] }],
      }));
    }
    handleModalClose();
  };

  return (
    <Box 
      id="project_managment"
      sx={{ display: 'flex', height: '100vh', fontFamily: 'Inter, sans-serif' }}
    >
      <Sidebar
        allOpen={allOpen}
        handleToggleAll={handleToggleAll}
        projectData={projectData}
        openSections={openSections}
        handleToggle={handleToggle}
        handleOpenModal={handleOpenModal}
        setPage={handlePageChange}  // passer `handlePageChange` ici pour changer `page`
        setSelectedStep={handleStepChange}
        setSelectedStepName={setSelectedStepName}
        setSelectedStepUsers={setSelectedStepUsers}
        loading={loading} // Pass loading state for opacity control
      />
      <MainContent 
          projectId={projectId}
              selectedStepName={selectedStepName}
              page={page}
              selectedStepUsers={selectedStepUsers}
              setSelectedUserId={setSelectedUserId}
      selectedStep={selectedStep} projectData={projectData} />

      <AddItemModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        modalType={modalType}
        users={projectData.users}
        newItemText={newItemText}
        setNewItemText={setNewItemText}
        handleSaveItem={handleSaveItem}
        projectId={projectId}
        RefreshSideBar={RefreshSideBar}
      />
      <UserDialog open={openDialog} onClose={handleCloseDialog} userId={selectedUserId} />
    </Box>
  );
};

export default Project;
