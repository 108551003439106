import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { styled } from '@mui/system';
import * as Icons from '@mui/icons-material';
import { Avatar, Typography, LinearProgress, Box } from '@mui/material';
import ProfileDetails from './ProfileDetails'; // Import the component

import { Link, useLocation } from 'react-router-dom';

// Define menu items structure
const menuItems = [
  { header: "Home", items: [{ title: "Dashboard", to: "/dashboard", icon: <Icons.DashboardOutlined /> }] },

  { header: "President", items: [
    { title: "New project", to: "/president/new-project", icon: <Icons.AddCircleOutline /> },
    { title: "Projects", to: "/president/projects",   icon: <Icons.AssignmentOutlined /> },
    { title: "New dataset", to: "/datasets/new-dataset", icon: <Icons.NoteAddOutlined /> }
  ]}, 
  { header: "GRH", items: [
    { title: "Users", to: "/users", icon: <Icons.GroupOutlined /> },
    { title: "Members", to: "/members", icon: <Icons.PeopleOutlineOutlined /> },
    { title: "Validation", to: "/validations", icon: <Icons.VerifiedUserOutlined /> },
    { title: "Universities", to: "/universities", icon: <Icons.SchoolOutlined /> }
  ]},

  { header: "Communication", items: [
    { title: "Social Medias", to: "/social-medias", icon: <Icons.ShareOutlined /> },
    { title: "Discord", to: "/discord", icon: <Icons.ChatOutlined /> },
    { title: "Emails", to: "/emails", icon: <Icons.EmailOutlined /> },
    { title: "Notifications", to: "/notifications", icon: <Icons.NotificationsOutlined /> },
    { title: "New Article", to: "/cm/article-editor", icon: <Icons.PostAddOutlined /> },
    { title: "Articles", to: "/articles", icon: <Icons.ArticleOutlined /> }
  ]},

  { header: "Main", items: [
    { title: "Events", to: "/calendar", icon: <Icons.EventOutlined /> },
    { title: "Projects", to: "/projects", icon: <Icons.AssignmentOutlined /> },
    { title: "Datasets", to: "/datasets", icon: <Icons.StorageOutlined /> },
    { title: "Ideas", to: "/ideas", icon: <Icons.LightbulbOutlined /> }
  ]},

  { header: "Others", items: [
    { title: "Profile", to: "/profile", icon: <Icons.PersonOutlined /> },
    { title: "Settings", to: "/settings", icon: <Icons.SettingsOutlined /> },
    { title: "Logout", to: "/logout", icon: <Icons.ExitToAppOutlined /> }
  ]}
];

// Styled Sidebar
const StyledSidebar = styled(Sidebar)(({ theme, isCollapsed }) => ({
  height: '100vh', 
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: '#ffffff',
  overflowY: 'auto',
  width: isCollapsed ? '80px !important' : '250px !important', // Adjust width based on collapse state
  borderRight: '1px solid #e0e0e0',
  padding: '0px 0',
  minWidth: '80px !important',
  transition: 'width 0.3s ease', // Add smooth transition
}));

// Menu Header Style
const MenuHeader = styled('div')(({ isCollapsed }) => ({
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#444',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  borderBottom: '1px solid #e0e0e0',
  marginBottom: '5px',
  display: isCollapsed ? 'none' : 'block', // Hide header when collapsed
}));

const ProfileSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderBottom: '1px solid #e0e0e0',
  gap: theme.spacing(1),
}));



const SidebarComponent = ({ isCollapsed }) => {
  const location = useLocation();
  const [profile, setProfile] = useState({
    picture: '',
    name: '',
    fname: '',
    xp: 0,
    level: 0,
    remainingXp: 0,
  });


  



    
  useEffect(() => {
    const picture = localStorage.getItem('picture');
    const name = localStorage.getItem('name');
    const fname = localStorage.getItem('fname');
    const xp = parseInt(localStorage.getItem('xp') || '9600', 10);
    const level = Math.floor(xp / 1000);
    const remainingXp = xp % 1000;

    setProfile({ picture, name, fname, xp, level, remainingXp });
  }, []);





  return (
    <>
      <StyledSidebar isCollapsed={isCollapsed}>
      <ProfileDetails 
      visible={isCollapsed}
      profile={profile} />


        <Menu>
          {menuItems.map((section, index) => (
            <div key={index}>
              <MenuHeader isCollapsed={isCollapsed}>{section.header}</MenuHeader>
              {section.items.map((item, idx) => (
                <MenuItem
                  key={idx}
                  icon={item.icon}
                  component={<Link to={`/dashboard${item.to}`} />}
                >
                  {!isCollapsed && item.title}
                </MenuItem>
              ))}
            </div>
          ))}
        </Menu>
      </StyledSidebar>
    </>
  );
};

export default SidebarComponent;
