import React from "react";
import Lottie from "react-lottie";
import animationData from "./anim.json"; // Adjust the path if necessary

const Loading = () => {
  const defaultOptions = {
    autoplay: true, // Automatically start the animation
    loop: true,     // Make the animation loop
    animationData: animationData, // Path to your Lottie animation data
  };

  const eventListeners = [
    {
      eventName: "loopComplete",
      callback: () => console.log("A loop complete"), // Optional event listener
    },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Lottie
        width={300}
        eventListeners={eventListeners}
        options={defaultOptions}
      />
    </div>
  );
};

export default Loading;
